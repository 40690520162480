import { Link } from "gatsby";
import React from "react";
import backdrop from "../images/header-image-07.png";

const Contact = () => {
	return (
		<div
			className="contact"
			style={{
				backgroundImage: `url(${backdrop})`,
			}}
		>
			<div className="container">
				<div className="row">
					<div className="col">
						<div className="card card-contact">
							<h3 className="card-contact-heading">Have a Question?</h3>
							<Link className="btn btn-primary" to="/contact-us#form">
								Contact Us
							</Link>
							<p className="card-contact-copy">
								Or just email{" "}
								<strong>
									<a href="mailto:medicalinfo@akebia.com">
										medicalinfo@akebia.com
									</a>
								</strong>
							</p>
							<h5 className="card-contact-phone-heading">
								Call Akebia Medical Information
							</h5>
							<p className="card-contact-phone no-margin">
								<a href="tel:+18444453799">1-844-445-3799</a>
							</p>
							<p className="card-contact-phone-instructions no-margin">
								To report an adverse event or product complaint, select option 2
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Contact;
