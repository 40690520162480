import React, { useState, useCallback } from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import serialize from 'form-serialize'
import jsonp from "jsonp"
//import CloseIcon from "../images/icon-close.inline.svg";

Modal.setAppElement("#___gatsby");


const ModalSignUp = props => {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [emailConfirm, setEmailConfirm] = useState("");
	const [credentials, setCredentials] = useState("");

	const [hasSubmitted, setHasSubmitted] = useState(false);
	const [submitSuccess, setSubmitSuccess] = useState(false);
	const [submitError, setSubmitError] = useState(false);

	const handleFormClose = useCallback(event => {
		setHasSubmitted(false);
		setSubmitSuccess(false);
		setSubmitError(false);
		props.onRequestClose()
	}, [props.onRequestClose])

	function validateFormText(text, minLength, maxLength) {
		if (text.length < minLength) {
			return false;
		} else {
			if (maxLength) {
				if (text.length > maxLength) {
					return false;
				}
			}
			return text;
		}
	}

	function validateEmail(email) {
		// eslint-disable-next-line
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (re.test(String(email).toLowerCase())) {
			return email;
		} else {
			return false;
		}
	}

	function validateForm() {
		var isValid = true;

		if (!validateFormText(firstName, 2)) {
			setFormError("mce-FNAME");
			isValid = false;
		}
		if (!validateFormText(lastName, 2)) {
			setFormError("mce-LNAME");
			isValid = false;
		}
		if (!validateEmail(email)) {
			setFormError("mce-EMAIL");
			isValid = false;
		}
		if (!validateEmail(emailConfirm)) {

			setFormError("mce-MMERGE4");
			isValid = false;
		} else {
			if (emailConfirm.toLowerCase() !== email.toLowerCase()) {
				setFormError("mce-MMERGE4");
				isValid = false;
			}
		}
		if (!validateFormText(credentials, 2)) {
			setFormError("mce-MMERGE3");
			isValid = false;
		}

		return isValid;
	}

	function processFormSubmit(e) {
		e.preventDefault();
		e.stopPropagation();
		removeErrors();

		if (validateForm()) {
			processSend(e);
		}
	}

	function processSend(e) {
		console.log("Submitting Sign-up Form!");
		e.preventDefault()
		// Usually, we would pass the final input values to a function that
		// would do something with the data like persist it to a database.
		// Using serialization, we just need to pass that function the
		// serialized form body.
		const form = e.currentTarget;
		var url = form.getAttribute('action');
		const ajaxURL = url.replace("/post?", "/post-json?");
		//console.log("Form URL: " + ajaxURL);
		var qs = "&FNAME=" + firstName;
		qs += "&LNAME=" + lastName;
		qs += "&EMAIL=" + email;
		qs += "&MMERGE4=" + emailConfirm;
		qs += "&MMERGE3=" + credentials;
		//console.log("Form QS: " + qs);
		var fullURL = ajaxURL + qs;
		//console.log("Full URL: " + fullURL);

		setHasSubmitted(true);
		//
		jsonp(fullURL, { param: "c" }, (err, data) => {
			if (data.msg.includes("already subscribed")) {
				console.log("User is already subscribed in MC");
				console.log(data.msg);
				var regX = /href=(["'])(.*?)\1/;
				var found = data.msg.match(regX);
				if (found) {
					console.log("Profile URL: " + found[2]);
				}
				setSubmitSuccess(true);
			} else if (err || data.result !== 'success') {
				console.log("There was an error with MC - check with admin");
				//setSubmitSuccess(false);
				//setSubmitError(true);
				setSubmitSuccess(true); // testing/screenshots
			} else {
				console.log("Successfully added to MC list");
				setSubmitSuccess(true);
			};
		});
	}

	function setFormError(id) {
		var el = document.getElementById(id);
		var err = el.getAttribute("data-error");
		if (!el.className.includes("form_error")) {
			el.classList.add("form_error");
			el.insertAdjacentHTML(
				"afterend",
				'<p class="form_error">' + err + "</p>"
			);
		}
	}

	function removeErrors() {
		var errors = document.querySelectorAll("p.form_error");
		[].forEach.call(errors, function (error) {
			error.remove();
		});
		var elems = document.querySelectorAll(".form_error");
		[].forEach.call(elems, function (el) {
			el.classList.remove("form_error");
		});
	}

	return (
		<Modal
			className="modal modal-sign-up"
			shouldCloseOnEsc={true}
			shouldCloseOnOverlayClick={true}
			{...props}
		>
			<div className="content">
				{!hasSubmitted && (
					<>
						<div className="content-top">
							<h2>JOIN OUR MAILING LIST</h2>
							<h3>To stay up to date on the latest Akebia research, educational resources, and disease state information.</h3>
						</div>
						<div className="form-signup">
							<div id="mc_embed_signup">

								<form action="https://akebiamedical.us5.list-manage.com/subscribe/post?u=554f83557e7ebcb635ff421a4&amp;id=313ab4a1c9" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate onSubmit={e => processFormSubmit(e)}>
									<div id="mc_embed_signup_scroll">
										<div className="col-1-1">
											<div className="col-1-2">
												<div className="mc-field-group">
													<input type="text" name="FNAME" className="required" id="mce-FNAME" placeholder="First Name*" data-error="First name is required" value={firstName} onChange={e => setFirstName(e.target.value)}
														onBlur={e => setFirstName(e.target.value)} />
												</div>
											</div>
											<div className="col-1-2">
												<div className="mc-field-group">
													<input type="text" name="LNAME" className="required" id="mce-LNAME" placeholder="Last Name*" data-error="Last name is required" value={lastName} onChange={e => setLastName(e.target.value)}
														onBlur={e => setLastName(e.target.value)} />
												</div>
											</div>
										</div>
										<div className="col-1-1">
											<div className="mc-field-group">
												<input type="text" name="MMERGE3" className="required" id="mce-MMERGE3" placeholder="Credentials*" data-error="Credentials is required" value={credentials} onChange={e => setCredentials(e.target.value)}
													onBlur={e => setCredentials(e.target.value)} />
											</div>
										</div>
										<div className="col-1-1">
											<div className="mc-field-group">
												<input type="email" name="EMAIL" className="required email" id="mce-EMAIL" placeholder="Email*" data-error="Email address is required" value={email} onChange={e => setEmail(e.target.value)}
													onBlur={e => setEmail(e.target.value)} />
											</div>
										</div>
										<div className="col-1-1 less-push">
											<div className="mc-field-group">
												<input type="email" name="MMERGE4" className="required email" id="mce-MMERGE4" placeholder="Confirm Email*" data-error="Email address must match" value={emailConfirm} onChange={e => setEmailConfirm(e.target.value)}
													onBlur={e => setEmailConfirm(e.target.value)} />
											</div>
										</div>
										<div className="col-1-1 less-push">
											<p className="footnote">*Indicates required field</p>
										</div>
										<div id="mce-responses" className="clear">
											<div className="response hidden" id="mce-error-response"></div>
											<div className="response hidden" id="mce-success-response"></div>
										</div>
										<div className="offscreen" aria-hidden="true">
											<input type="text" name="b_554f83557e7ebcb635ff421a4_313ab4a1c9" tabIndex="-1" />
										</div>
										<div className="row">
											<div className="col-sm-6 center">
												<button type="button"
													onClick={handleFormClose}
													className="btn btn-primary">
													Cancel
												</button>
											</div>
											<div className="col-sm-6 center">
												<button id="mc-embedded-subscribe" type="submit" className="btn btn-primary">
													Submit
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
							<p className="disclaimer center">By providing my information above, I agree to the Akebia Therapeutics, Inc. (Akebia) <a className="" href="https://akebia.com/privacy-policy/" target="_blank" rel="noreferrer">
								Privacy Policy
							</a> and give Akebia and its affiliates, service providers, and contractors permission to contact me via email for marketing purposes or otherwise provide me with information about company products, services, programs, and other topics of interest.</p>

						</div>
					</>
				)}
				{hasSubmitted && submitSuccess && (
					<>
						<div id="mc_form_status" className="form-status">
							<h5>Thank you for signing up!</h5>
							<p>You’ll receive emails periodically when new information becomes available.</p>
							<div className="row">
								<div className="col-sm-12 center">
									<button type="button"
										onClick={handleFormClose}
										className="btn btn-primary">
										Close
									</button>
								</div>
							</div>
						</div>
					</>
				)}
				{hasSubmitted && submitError && (
					<>
						<div id="mc_form_status" className="form-status">
							<h5>We're sorry.</h5>
							<p>There was an unknown error with your submission. Please try again later.</p>
							<div className="row">
								<div className="col-sm-12 center">
									<button type="button"
										onClick={handleFormClose}
										className="btn btn-primary">
										Close
									</button>
								</div>
							</div>
						</div>
					</>
				)}
			</div>

		</Modal>
	);
};

// ModalSignUp.propTypes = {
// 	isOpen: PropTypes.bool.isRequired,
// };

export default ModalSignUp;
