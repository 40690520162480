import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import favicon from "../images/favicon-32x32.png";

function SEO({ description, lang, meta, title, canonical }) {
	const { site } = useStaticQuery(
		graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
	);

	const metaDescription = description || site.siteMetadata.description;
	const defaultTitle = site.siteMetadata?.title;

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
			// link={
			// 	canonical
			// 		? [{ rel: 'canonical', key: canonical, href: canonical }]
			// 		: []
			// }
			link={[
				{ rel: "icon", type: "image/png", href: favicon }
			]}
			meta={[
				{
					name: `google-site-verification`,
					content: `jMqD8LLNW5Gg7DZ0qYnxETXMEF8fmBtrD4P8zdtKGJg`,
				},
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:title`,
					content: title,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:creator`,
					content: site.siteMetadata?.author || ``,
				},
				{
					name: `twitter:title`,
					content: title,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
			].concat(meta)}
		>
			<script
				src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js"
				type="text/javascript"
				charset="UTF-8"
				data-domain-script="e022b56f-4358-473a-8743-73e6b3de21e4"
			></script>
			<script type="text/javascript">function OptanonWrapper() { }</script>
		</Helmet>
	);
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
};

SEO.propTypes = {
	canonical: PropTypes.string,
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired,
};

export default SEO;
