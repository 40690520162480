import React, { useState } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import Preheader from "./preheader";
import Menu from "./menu";

import logo from "../images/akebia-logo-green.png";
import HamburgerIcon from "../images/icon-hamburger.inline.svg";
import CloseIcon from "../images/icon-close.inline.svg";

const Header = () => {
	const [openMobileMenu, setOpenMobileMenu] = useState(false);

	return (
		<>
			<Preheader />
			<div className={openMobileMenu ? "mobile-menu active" : "mobile-menu"}>
				<div className="container">
					<div className="row">
						<div className="col">
							<Menu />
						</div>
					</div>
				</div>
			</div>
			<div className="mobile-header">
				<div className="container">
					<div className="header">
						<div className="col-3">
							<Link to="/">
								<img
									src={logo}
									className="mobile-header-logo header-logo"
									alt="Akebia Medical Affairs"
								/>
							</Link>
						</div>
						<div className="col-9 mobile-header-hamburger-wrapper">
							<button
								className="mobile-header-hamburger"
								onClick={e => setOpenMobileMenu(!openMobileMenu)}
							>
								{openMobileMenu ? <CloseIcon /> : <HamburgerIcon />}
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="header-wrapper">
				<div className="container">
					<header className="header">
						<div className="col-3 header-logo-column">
							<Link to="/">
								<img
									src={logo}
									className="header-logo"
									alt="Akebia Medical Affairs"
								/>
							</Link>
						</div>
						<div className="col-sm-9 header-nav-column">
							<div>
								<Menu />
							</div>
						</div>
					</header>
				</div>
			</div>
		</>
	);
};

Header.propTypes = {
	siteTitle: PropTypes.string,
};

Header.defaultProps = {
	siteTitle: ``,
};

export default Header;
