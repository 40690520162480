import React from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";

Modal.setAppElement("#___gatsby");

const ModalLeavingInterstitial = ({ isOpen, link, callback }) => {
	return (
		<Modal className="modal modal-leaving" isOpen={isOpen}>
			<p>
				You are leaving <strong>akebiamedicalaffairs.com</strong>, a website of
				Akebia Therapeutics, Inc. You will now be directed to a third-party
				website. Akebia’s privacy procedures do not apply to third-party sites,
				nor does Akebia assume any responsibility for content on external sites.
			</p>
			<p className="button-wrapper">
				<a
					className="btn btn-primary"
					href={link}
					target="_blank"
					rel="noreferrer"
					onClick={e => callback()}
				>
					Leave Site
				</a>
				<button className="btn btn-primary" onClick={e => callback()}>
					Cancel
				</button>
			</p>
		</Modal>
	);
};

ModalLeavingInterstitial.propTypes = {
	link: PropTypes.string.isRequired,
	isOpen: PropTypes.bool,
};

export default ModalLeavingInterstitial;
