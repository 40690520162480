import React from "react";
import PropTypes from "prop-types";

import { useModal } from "../context/context";

import Header from "./header";
import Contact from "./contact";
import Footer from "./footer";
import ModalHCPInterstitial from "./modalHCPInterstitial";
import ModalLeavingInterstitial from "./modalLeavingInterstitial";

import "../styles/main.scss";

const Layout = ({ children, contact }) => {
	const { can_show_modal, link, closeModal } = useModal();

	return (
		<>
			<ModalHCPInterstitial />
			<ModalLeavingInterstitial
				isOpen={can_show_modal}
				link={link}
				callback={closeModal}
			/>
			<div className="container-xl">
				<div className="row">
					<div className="col">
						<Header siteTitle="Akebia Medical Affairs" />
					</div>
				</div>
				<div className="row">
					<main className="col" id="main">
						{children}
					</main>
				</div>
				{contact && (
					<div className="row">
						<div className="col">
							<Contact />
						</div>
					</div>
				)}
				<div className="row">
					<footer className="col" id="footer">
						<Footer />
					</footer>
				</div>
			</div>
		</>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	contact: PropTypes.bool,
};

export default Layout;
