import React from "react";
import { useCookies } from "react-cookie";
import Modal from "react-modal";

Modal.setAppElement("#___gatsby");

const ModalHCPInterstitial = () => {
	const [cookies, setCookie] = useCookies(["akebia-hcp"]);

	return (
		<Modal
			className="modal modal-hcp"
			isOpen={cookies["akebia-hcp"] !== "true"}
		>
			<h3>Are you a U.S. Healthcare Professional?</h3>
			<p>
				The medical information provided on this site is intended for use by
				U.S. healthcare professionals. This website is intended to provide
				scientifically balanced, evidence-based information, submit questions,
				ask for field medical follow-up, and explore links to professional
				support resources. The information is not intended as medical advice.
			</p>
			<p className="button-wrapper">
				<button
					id="btn-yes"
					className="btn btn-primary"
					onClick={() => {
						setCookie("akebia-hcp", "true");
					}}
				>
					Yes
				</button>
				<a
					id="btn-no"
					className="btn btn-primary"
					href="https://www.akebia.com"
				>
					No
				</a>
			</p>
		</Modal>
	);
};

export default ModalHCPInterstitial;
