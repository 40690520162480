import React, { useEffect } from "react";

const Preheader = () => (
	<><div id="preheader-wrapper">
		<div className="preheader">
			<div className="col">
				<p className="preheader-text">
					THIS SITE IS FOR U.S. HEALTHCARE PROFESSIONALS ONLY
				</p>
			</div>
		</div>
	</div></>
);

export default Preheader;
